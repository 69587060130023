import { useState, useEffect } from 'react';

const useScale = (imageUri: string, newScale: number, layer: any, boundingBox?: Rectangle | null) => {
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [stageScale, setStageScale] = useState(1)
  const handleWheel = (e: any) => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

    stage.scale({ x: newScale, y: newScale });
    setStageScale(newScale);
    setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
    setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
  };

  const zoomTo = (boundingBox: Rectangle, newScale: number) => {
    // center horizontally
    const x = (boundingBox.x - 100) * newScale;
    // center horizontally
    const y = (boundingBox.y - 50) * newScale;
    setStageX(-x);
    setStageY(-y);
    setStageScale(newScale);
  };

  // zoom to damage when selected
  useEffect(
    () => {
      layer?.position({ x: 0, y: 0 });
      layer?.draw();
      setStageScale(1);
      setStageX(0);
      setStageY(0);
      if (boundingBox) {
        zoomTo(boundingBox, newScale);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(boundingBox)]
  );
  // reset zoom to 0 when changing image through index
  useEffect(
    () => {
      layer?.position({ x: 0, y: 0 });
      layer?.draw();
      if (!boundingBox) {
        setStageScale(1);
        setStageX(0);
        setStageY(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageUri]
  );

  return {
    handleWheel,
    stageX,
    stageY,
    stageScale,
    zoomTo,
  };

};

export { useScale };

import React from 'react'
import _ from 'lodash'

import { useGetTurnarounds, useSearchTurnaround } from '@/hooks/Turnaround';

import { getMessage } from '@/translations'
import TurnaroundItem from '@/components/TurnaroundItem'
import Layout from '@/connectedComponents/Layout'
import SearchBar from '@/components/SearchBar';

const Home = () => {
  const { turnarounds } = useGetTurnarounds()
  const { query, onChange, searchedTurnarounds, isLoading } = useSearchTurnaround();

  return (
    <Layout leftTitle={getMessage('Home.title')} middleTitle="">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 80,
        }}
      >
        <div style={{ width: '80%' }}>
          <div>
            <SearchBar value={query} onChange={onChange} loading={isLoading} />
          </div>
          {_.map(query ? searchedTurnarounds : turnarounds, (turnaround) => (
            <TurnaroundItem key={turnaround.id} turnaround={turnaround} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Home

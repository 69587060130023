import React from 'react'

import Routes from '@/Routes'

import '@/App.css'

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  )
}

export default App

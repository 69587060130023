import { useDocument } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'

const useGetTurnarounds = (docId: string) => {
  const [value, loading, error] = useDocument(
    firebase.firestore().collection('turnarounds').doc(docId)
  )
  const turnaroundData = value?.data()
  const turnaround = {
    ...turnaroundData,
    dateOfCreation: turnaroundData?.dateOfCreation.toDate(),
  } as Turnaround
  return {
    turnaround,
    loading,
    error,
  }
}

export default useGetTurnarounds

import { getMessage } from '@/translations'
import { partTypeToCode } from '@/constants/partAndDamages'
import { LanguageKey } from '@/translations/messages'

export const getPartMessage = (partType: number) => {
  const partTypeMessage = getMessage(
    `PART_TYPE.${
      partTypeToCode[
        partType?.toString() as keyof typeof partTypeToCode
      ]
    }` as LanguageKey
  )
  return partTypeMessage;
};

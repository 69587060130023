import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import ArrowBack from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { PAGES } from '@/constants/routes'
import { logout } from '@/pages/Login/actions'
import { getMessage } from '@/translations'

type Props = {
  leftTitle: string
  middleTitle: string | React.ReactNode
  children: React.ReactNode
  withBackArrow?: boolean
  hideOverFlow?: boolean,
}

const Layout = ({ hideOverFlow, leftTitle, middleTitle, children, withBackArrow }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const onLogout = () => {
    dispatch(logout())
    history.replace(PAGES.LOGIN)
  }
  return (
    <div style={hideOverFlow ? { overflow: 'hidden', height: '100vh' } : undefined}>
      <AppBar position="fixed">
        <div style={{ display: 'flex' }}>
          {withBackArrow && (
            <Toolbar>
              <IconButton
                aria-label="delete"
                onClick={() => history.replace(PAGES.HOME)}
              >
                <ArrowBack fontSize="large" htmlColor="white" />
              </IconButton>
            </Toolbar>
          )}
          <Toolbar>
            <div style={{ fontSize: 20 }}>{leftTitle}</div>
          </Toolbar>
          <div style={{ flex: 1 }} />
          <Toolbar>
            <div style={{ fontSize: 20 }}>{middleTitle}</div>
          </Toolbar>
          <div style={{ flex: 1 }} />
          <Button
            color="inherit"
            style={{ marginRight: 20 }}
            onClick={onLogout}
          >
            {getMessage('Home.Logout')}
          </Button>
        </div>
      </AppBar>
      {children}
    </div>
  )
}

export default Layout

const translations = {
  'EditTurnAround.cleanliness.clean': 'Clean',
  'EditTurnAround.cleanliness.dirty': 'Dirty',
  'EditTurnAround.cleanliness.wet': 'Wet',
  'Home.title': 'Home',
  'Home.search': 'Search by model, brand, registering, vin, ...',
  'Home.Logout': 'Logout',
  'Login.title': 'Log in',
  'Login.subtitle': 'Ai-based inspection system',
  'Login.email': 'Email',
  'Login.password': 'Password',
  'Login.submit': 'Submit',
  'TurnaroundItem.seeMore': 'See more',
  'Turnaround.damages': 'damages',
  'TurnaroundItem.predictionNotReady': 'Waiting for prediction',
  'TurnaroundItem.notACar': 'You cannot add a damage for this image',
  'Turnaround.title': 'Turnaround',
  'Turnaround.noDamage': 'There is no damage',

  'DAMAGE_TYPE.body_crack': 'Body Crack',
  'DAMAGE_TYPE.broken_glass': 'Broken Glass',
  'DAMAGE_TYPE.broken_light': 'Broken Light',
  'DAMAGE_TYPE.dent': 'Dent',
  'DAMAGE_TYPE.hubcap_scratch': 'Hubcap Scratch',
  'DAMAGE_TYPE.misshape': 'Misshape',
  'DAMAGE_TYPE.missing_hubcap': 'Missing Hubcap',
  'DAMAGE_TYPE.missing_piece': 'Missing Piece',
  'DAMAGE_TYPE.paint_peeling': 'Paint Peeling',
  'DAMAGE_TYPE.rustiness': 'Rust',
  'DAMAGE_TYPE.scattered_scratches': 'Scattered Scratches',
  'DAMAGE_TYPE.scratch': 'Scratch',
  'DAMAGE_TYPE.smash': 'Smash',
  'PART_TYPE.bumper_back': 'bumper back',
  'PART_TYPE.bumper_front': 'bumper front',
  'PART_TYPE.door_back_left': 'door back left',
  'PART_TYPE.door_back_right': 'door back right',
  'PART_TYPE.door_front_left': 'door front left',
  'PART_TYPE.door_front_right': 'door front right',
  'PART_TYPE.fender_back_left': 'fender back left',
  'PART_TYPE.fender_back_right': 'fender back right',
  'PART_TYPE.fender_front_left': 'fender front left',
  'PART_TYPE.fender_front_right': 'fender front right',
  'PART_TYPE.Fog lights': 'Fog lights',
  'PART_TYPE.fog_light_back_left': 'fog light back left',
  'PART_TYPE.fog_light_back_right': 'fog light back right',
  'PART_TYPE.fog_light_front_left': 'fog light front left',
  'PART_TYPE.fog_light_front_right': 'fog light front right',
  'PART_TYPE.grill': 'grill',
  'PART_TYPE.grill_low': 'grill low',
  'PART_TYPE.grill_radiator': 'grill radiator',
  'PART_TYPE.handle_back_left': 'handle back left',
  'PART_TYPE.handle_back_right': 'handle back right',
  'PART_TYPE.handle_front_left': 'handle front left',
  'PART_TYPE.handle_front_right': 'handle front right',
  'PART_TYPE.head_light_left': 'head light left',
  'PART_TYPE.head_light_right': 'head light right',
  'PART_TYPE.header_panel': 'header panel',
  'PART_TYPE.hood': 'hood',
  'PART_TYPE.hook': 'hook',
  'PART_TYPE.hubcap_back_left': 'hubcap back left',
  'PART_TYPE.hubcap_back_right': 'hubcap back right',
  'PART_TYPE.hubcap_front_left': 'hubcap front left',
  'PART_TYPE.hubcap_front_right': 'hubcap front right',
  'PART_TYPE.indicator_light_left': 'indicator light left',
  'PART_TYPE.indicator_light_right': 'indicator light right',
  'PART_TYPE.license_plate_back': 'license plate back',
  'PART_TYPE.license_plate_front': 'license plate front',
  'PART_TYPE.logo': 'logo',
  'PART_TYPE.mirror_left': 'mirror left',
  'PART_TYPE.mirror_right': 'mirror right',
  'PART_TYPE.mirror_support': 'mirror support',
  'PART_TYPE.Other lights': 'Other lights',
  'PART_TYPE.petrol_door': 'petrol door',
  'PART_TYPE.pillar': 'pillar',
  'PART_TYPE.quarter_window_back_left': 'quarter window back left',
  'PART_TYPE.quarter_window_back_right': 'quarter window back right',
  'PART_TYPE.quarter_window_front_left': 'quarter window front left',
  'PART_TYPE.quarter_window_front_right': 'quarter window front right',
  'PART_TYPE.rear_spoiler': 'rear spoiler',
  'PART_TYPE.rocker_panel': 'rocker panel',
  'PART_TYPE.rocker_panel_left': 'rocker panel left',
  'PART_TYPE.rocker_panel_right': 'rocker panel right',
  'PART_TYPE.roof': 'roof',
  'PART_TYPE.tail_light_center': 'tail light center',
  'PART_TYPE.tail_light_left': 'tail light left',
  'PART_TYPE.tail_light_right': 'tail light right',
  'PART_TYPE.trunk': 'trunk',
  'PART_TYPE.turn_signal_front_lateral_left': 'turn signal front lateral left',
  'PART_TYPE.turn_signal_front_lateral_right':
    'turn signal front lateral right',
  'PART_TYPE.wheel_back_left': 'wheel back left',
  'PART_TYPE.wheel_back_right': 'wheel back right',
  'PART_TYPE.wheel_front_left': 'wheel front left',
  'PART_TYPE.wheel_front_right': 'wheel front right',
  'PART_TYPE.window_back_left': 'window back left',
  'PART_TYPE.window_back_right': 'window back right',
  'PART_TYPE.window_corner_left': 'window corner left',
  'PART_TYPE.window_corner_right': 'window corner right',
  'PART_TYPE.window_front_left': 'window front left',
  'PART_TYPE.window_front_right': 'window front right',
  'PART_TYPE.windshield_back': 'windshield back',
  'PART_TYPE.windshield_front': 'windshield front',
  'PART_TYPE.wiper': 'wiper',
  'PART_TYPE.wiper_back': 'wiper back',
  'PART_TYPE.wiper_front': 'wiper front',
}

export type TranslationEn = keyof typeof translations

export default translations

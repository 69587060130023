import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

type Props = {
  color: 'primary' | 'secondary'
  icon: React.ReactNode
  onClick?: Function
  stopPropagation?: boolean
  style: Object | null | undefined
  title: string | null | undefined
}

const MonkIconButton = ({
  color,
  icon,
  onClick,
  stopPropagation,
  style,
  title,
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (stopPropagation) e.stopPropagation()
    if (onClick) onClick()
  }

  return (
    <Tooltip title={title ?? ''} placement="top" enterDelay={200}>
      <IconButton
        color={color}
        onClick={handleClick}
        style={{ padding: 4, ...style }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}

MonkIconButton.defaultProps = {
  color: undefined,
  dataTestId: undefined,
  onClick: undefined,
  stopPropagation: undefined,
  style: undefined,
  title: undefined,
}

export default MonkIconButton

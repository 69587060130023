import React, { useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler'

import Box from '@material-ui/core/Box';

import { validateTurnaround } from '@/services/firestore/turnarounds'
import { getIsImageWithCar } from '@/services/dataModel/turnaround';

import Separator from '@/components/Separator';
import GroupedDamageViews from '@/components/GroupedDamageViews';
import GroupedImages from '@/components/GroupedImages';
import ValidateTurnaroundModal from '@/components/Modals/ValidateTurnaround'
import AddDamageModal from '@/components/Modals/AddDamage'
import Button from '@/components/Button'
import { getMessage } from '@/translations'
import TurnaroundMetaData from '@/components/TurnaroundMeta';

const styles: StyleSheets = {
  container: {
    display:'flex',
    flexDirection:'column',
    overflow: 'hidden',
    height:'100%'
  },
};

type Props = {
  damageViewIndex: number | null,
  setDamageAndImageIndex: (value: number) => void
  damageViews: DamageViewWithIndex[],
  hasTurnaroundBeenValidated: boolean
  isAllValidated: boolean
  selectedImage: Image
  partViewsInImage: { [apiId: string]: PartView }
  validateOrDeleteDamageView: Function,
  turnaround: Turnaround,
  images: Array<any>,
  imageIndex: number,
  setImageIndex: (index: number) => void,
}

const RightComponent = ({
  damageViewIndex,
  setDamageAndImageIndex,
  damageViews,
  hasTurnaroundBeenValidated,
  images,
  imageIndex,
  setImageIndex,
  isAllValidated,
  selectedImage,
  partViewsInImage,
  validateOrDeleteDamageView,
  turnaround,
}: Props) => {
  const selectedImageApiId = selectedImage.apiId
  const isImageWithCar = getIsImageWithCar(selectedImage);
  const { turnaroundId } = useParams<{ turnaroundId: string }>()
  const [
    isValidateTurnaroundModalOpen,
    setIsValidateTurnaroundModalOpen,
  ] = useState(false)
  const [isAddDamageModalOpen, setIsAddDamageModalOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const onValidateTurnaround = async () => {
    await validateTurnaround(turnaroundId)
    setIsValidateTurnaroundModalOpen(false)
    enqueueSnackbar('The turnaround has been validated', { variant: 'success' })
  }
  const onClickOnValidateTurnaround = () => {
    if (isAllValidated) setIsValidateTurnaroundModalOpen(true)
    else
      enqueueSnackbar('You first need to validate all damages', {
        variant: 'error',
      })
  }
  const onPressUpKey = () => {
    if (!_.isNil(damageViewIndex)) {
      if (damageViewIndex === 0)
        setDamageAndImageIndex(_.size(damageViews) - 1)
      else
        setDamageAndImageIndex(
          (damageViewIndex - 1) % _.size(damageViews)
        )
    }
  }
  const onPressDownKey = () => {
    if (!_.isNil(damageViewIndex)) {
      setDamageAndImageIndex(
        (damageViewIndex + 1) % _.size(damageViews)
      )
    }
  }

  return (
    <div style={styles.container}>
      <TurnaroundMetaData turnaround={turnaround} />
      <Separator />      
      <Box style={{ overflow: 'auto', flexGrow:1, display:'flex', height:100}}>
        {!hasTurnaroundBeenValidated ? <GroupedDamageViews
          damageViews={damageViews}
          damageViewIndex={damageViewIndex}
          setDamageAndImageIndex={setDamageAndImageIndex}
          hasTurnaroundBeenValidated={hasTurnaroundBeenValidated}
          validateOrDeleteDamageView={validateOrDeleteDamageView}
        /> :
          <GroupedImages
            damageViews={damageViews}
            images={images}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
          />}
        {_.size(damageViews) === 0 && (
          <div style={{ textAlign: 'center' }}>
            {getMessage('Turnaround.noDamage')}
          </div>
        )}
      </Box>
      {!hasTurnaroundBeenValidated && (
        <>
          <Separator />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {isImageWithCar ? <>
              <Button
                label="Validate turnaound"
                onClick={onClickOnValidateTurnaround}
                isOulined
              />
              <div style={{ marginLeft: 10 }} />
              <Button
                label="Add damage"
                onClick={() => setIsAddDamageModalOpen(true)}
                isOulined
              />
            </> : <div style={{ marginBottom: 20 }}>{getMessage('TurnaroundItem.notACar')}</div>}
          </Box>
        </>
      )}
      <ValidateTurnaroundModal
        isOpen={isValidateTurnaroundModalOpen}
        handleClose={() => setIsValidateTurnaroundModalOpen(false)}
        onConfirm={onValidateTurnaround}
      />
      <AddDamageModal
        isOpen={isAddDamageModalOpen}
        handleClose={() => setIsAddDamageModalOpen(false)}
        imageApiId={selectedImageApiId}
        partViewsInImage={partViewsInImage}
        selectedImage={selectedImage}
      />
      <KeyboardEventHandler
        handleKeys={['up', 'down']}
        onKeyEvent={(key: any) => {
          if (key === 'up') onPressUpKey()
          else onPressDownKey()
        }}
      />
      <KeyboardEventHandler
        handleKeys={['enter', 'delete', 'backspace']}
        onKeyEvent={async (key: any) => {
          if (key === 'enter') await validateOrDeleteDamageView(false)
          else await validateOrDeleteDamageView(true)
        }}
      />
    </div>
  )
}

export default RightComponent

import * as React from 'react';

const styles: StyleSheets = {
  separator: {
    borderTopColor: '#E5E5E5',
    borderTopStyle: 'solid',
    borderTopWidth: 0.5,
    marginBottom: 10,
    marginTop: 10,
  },
};

const Separator = () => {
  return (
    <div style={styles.separator} />
  );
}

export default Separator;

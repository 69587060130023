import Card from '@/components/Card'
import { PAGES } from '@/constants/routes'
import { cleanlinessOptions } from '@/constants/turnaround'
import {
  getDamagesCount,
  getImagesWithCar,
} from '@/services/dataModel/turnaround'
import { getMessage } from '@/translations'
import Check from '@material-ui/icons/Check'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  turnaround: Turnaround
}

const styles: StyleSheets = {
  images: {
    borderRadius: 5,
    maxWidth: 200,
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
  },
  imageContainer: {
    marginRight: 10,
    borderRadius: 5,
  },
  link: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
}

const TurnaroundItem = ({ turnaround }: Props) => {
  const {
    total: totalDamagesCount,
    nonDeleted: unDeletedDamagesCount,
  } = getDamagesCount(turnaround)

  const hasTurnaoudBeenValidated = Boolean(turnaround.dateOfValidation)
  const imagesWithCar = _.values(
    getImagesWithCar(turnaround.predictionsByViews)
  )
  const isPredictionReady = !_.isNil(turnaround.dateOfPredictionReception)
  const carIsVisible = _.size(imagesWithCar) > 0
  const turnaroundId = turnaround.id
  const { vin, registering, brand, model, cleanliness } = turnaround
  const cleanlinessText = _.find(
    cleanlinessOptions,
    (option) => option.code === cleanliness
  )?.text
  const title = _.join(
    _.compact([vin, registering, brand, model, cleanlinessText]),
    ' - '
  )

  const turnaroundReady = (
    <>
      <div style={styles.link}>
        <div style={{ flex: 1 }} />
      </div>
      <div style={styles.imagesContainer}>
        {carIsVisible && (
          <div style={styles.imageContainer} key={imagesWithCar[0].apiId}>
            <img src={imagesWithCar[0].url} style={styles.images} alt="" />
          </div>
        )}
      </div>
    </>
  )

  const turnaroundNotReady = (
    <>
      <div style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}>
        {getMessage('TurnaroundItem.predictionNotReady')}
      </div>
    </>
  )

  const TurnaroundContent = isPredictionReady
    ? turnaroundReady
    : turnaroundNotReady

  return (
    <Card style={{ marginTop: 20, marginBottom: 20 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-',
          alignItems: 'center',
        }}
      >
        <div style={{ fontSize: 20, fontWeight: 'bold', flex: '1 1 0px' }}>
          {title}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 1 0px',
          }}
        >
          {hasTurnaoudBeenValidated && (
            <Check fontSize="large" htmlColor="green" />
          )}
          {hasTurnaoudBeenValidated ? unDeletedDamagesCount : totalDamagesCount}{' '}
          {getMessage('Turnaround.damages')}
        </div>

        <div style={{ flex: '1 1 0px', textAlign: 'end' }}>
          {carIsVisible && (
            <Link to={`${PAGES.TURN_AROUND}/${turnaroundId}`}>
              {getMessage('TurnaroundItem.seeMore')}
            </Link>
          )}
        </div>
      </div>
      {TurnaroundContent}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
        <div>{turnaround.vinOrRegistering}</div>
        <div style={{ flex: 1 }} />
        <div>
          {moment(turnaround.dateOfCreation).format('DD-MM-YYYY HH:mm')}
        </div>
      </div>
    </Card>
  )
}

export default TurnaroundItem

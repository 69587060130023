import React from 'react'

import Button from '@/components/Button'
import Modal from '@/components/Modals'

type Props = {
  isOpen: boolean
  handleClose: any
  onConfirm: () => void
}

const ValidateTurnaround = ({ isOpen, handleClose, onConfirm }: Props) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div style={{ textAlign: 'center' }}>
        Are you sure you want to validate the turnaround?
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 30,
          justifyContent: 'space-around',
        }}
      >
        <Button label="Cancel" onClick={handleClose} isSecondary />
        <Button label="Confirm" onClick={onConfirm} />
      </div>
    </Modal>
  )
}

export default ValidateTurnaround

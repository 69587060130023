import React from 'react'
import Dialog from '@material-ui/core/Dialog'

type Props = {
  isOpen: boolean
  handleClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  children: React.ReactNode
  maxWidth?: 'sm' | 'lg' | undefined
}

const styles: StyleSheets = {
  container: { padding: 20, position: 'relative' },
}

const Modal = ({ isOpen, handleClose, children, maxWidth = 'sm' }: Props) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={maxWidth}>
      <div style={styles.container}>{children}</div>
    </Dialog>
  )
}

export default Modal

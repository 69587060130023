import { getLocalLanguage } from '@/services/language'

export const partTypeToCode = {
  '0': 'bumper_back',
  '1': 'bumper_front',
  '2': 'door_back_left',
  '3': 'door_back_right',
  '4': 'door_front_left',
  '5': 'door_front_right',
  '6': 'fender_back_left',
  '7': 'fender_back_right',
  '8': 'fender_front_left',
  '9': 'fender_front_right',
  '10': 'fog_light_back_left',
  '11': 'fog_light_back_right',
  '12': 'fog_light_front_left',
  '13': 'fog_light_front_right',
  '14': 'grill',
  '15': 'handle_back_left',
  '16': 'handle_back_right',
  '17': 'handle_front_left',
  '18': 'handle_front_right',
  '19': 'head_light_left',
  '20': 'head_light_right',
  '21': 'header_panel',
  '22': 'hood',
  '23': 'hubcap_back_left',
  '24': 'hubcap_back_right',
  '25': 'hubcap_front_left',
  '26': 'hubcap_front_right',
  '27': 'indicator_light_left',
  '28': 'indicator_light_right',
  '29': 'license_plate_back',
  '30': 'license_plate_front',
  '31': 'logo',
  '32': 'miror_support',
  '33': 'mirror_left',
  '34': 'mirror_right',
  '35': 'pillar',
  '36': 'quarter_window_back_left',
  '37': 'quarter_window_back_right',
  '38': 'quarter_window_front_left',
  '39': 'quarter_window_front_right',
  '40': 'rocker_panel',
  '41': 'roof',
  '42': 'tail_light_left',
  '43': 'tail_light_right',
  '44': 'trunk',
  '45': 'wheel_back_left',
  '46': 'wheel_back_right',
  '47': 'wheel_front_left',
  '48': 'wheel_front_right',
  '49': 'window_back_left',
  '50': 'window_back_right',
  '51': 'window_front_left',
  '52': 'window_front_right',
  '53': 'windshield_back',
  '54': 'windshield_front',
  '55': 'wiper',
}

export const damageTypeToCode = {
  '0': 'broken_glass',
  '1': 'rustiness',
  '2': 'missing_piece',
  '3': 'missing_hubcap',
  '4': 'body_crack',
  '5': 'misshape',
  '6': 'smash',
  '7': 'dent',
  '8': 'scratch',
  '9': 'broken_light',
}

const damageChoicesEN = {
  '0': { code: '0', text: 'broken glass' },
  '1': { code: '1', text: 'rustiness' },
  '2': { code: '2', text: 'missing piece' },
  '3': { code: '3', text: 'missing hubcap' },
  '4': { code: '4', text: 'body crack' },
  '5': { code: '5', text: 'misshape' },
  '6': { code: '6', text: 'smash' },
  '7': { code: '7', text: 'dent' },
  '8': { code: '8', text: 'scratch' },
  '9': { code: '9', text: 'broken light' },
}

const partChoicesEN = {
  '0': { code: '0', text: 'bumper back' },
  '1': { code: '1', text: 'bumper front' },
  '2': { code: '2', text: 'door back left' },
  '3': { code: '3', text: 'door back right' },
  '4': { code: '4', text: 'door front left' },
  '5': { code: '5', text: 'door front right' },
  '6': { code: '6', text: 'fender back left' },
  '7': { code: '7', text: 'fender back right' },
  '8': { code: '8', text: 'fender front left' },
  '9': { code: '9', text: 'fender front right' },
  '10': { code: '10', text: 'fog light back left' },
  '11': { code: '11', text: 'fog light back right' },
  '12': { code: '12', text: 'fog light front left' },
  '13': { code: '13', text: 'fog light front right' },
  '14': { code: '14', text: 'grill' },
  '15': { code: '15', text: 'handle back left' },
  '16': { code: '16', text: 'handle back right' },
  '17': { code: '17', text: 'handle front left' },
  '18': { code: '18', text: 'handle front right' },
  '19': { code: '19', text: 'head light left' },
  '20': { code: '20', text: 'head light right' },
  '21': { code: '21', text: 'header panel' },
  '22': { code: '22', text: 'hood' },
  '23': { code: '23', text: 'hubcap back left' },
  '24': { code: '24', text: 'hubcap back right' },
  '25': { code: '25', text: 'hubcap front left' },
  '26': { code: '26', text: 'hubcap front right' },
  '27': { code: '27', text: 'indicator light left' },
  '28': { code: '28', text: 'indicator light right' },
  '29': { code: '29', text: 'license plate back' },
  '30': { code: '30', text: 'license plate front' },
  '31': { code: '31', text: 'logo' },
  '32': { code: '32', text: 'miror support' },
  '33': { code: '33', text: 'mirror left' },
  '34': { code: '34', text: 'mirror right' },
  '35': { code: '35', text: 'pillar' },
  '36': { code: '36', text: 'quarter window back left' },
  '37': { code: '37', text: 'quarter window back right' },
  '38': { code: '38', text: 'quarter window front left' },
  '39': { code: '39', text: 'quarter window front right' },
  '40': { code: '40', text: 'rocker panel' },
  '41': { code: '41', text: 'roof' },
  '42': { code: '42', text: 'tail light left' },
  '43': { code: '43', text: 'tail light right' },
  '44': { code: '44', text: 'trunk' },
  '45': { code: '45', text: 'wheel back left' },
  '46': { code: '46', text: 'wheel back right' },
  '47': { code: '47', text: 'wheel front left' },
  '48': { code: '48', text: 'wheel front right' },
  '49': { code: '49', text: 'window back left' },
  '50': { code: '50', text: 'window back right' },
  '51': { code: '51', text: 'window front left' },
  '52': { code: '52', text: 'window front right' },
  '53': { code: '53', text: 'windshield back' },
  '54': { code: '54', text: 'windshield front' },
  '55': { code: '55', text: 'wiper' },
}

const damageChoicesFR = {
  '0': { code: '0', text: 'Bris de Glace' },
  '1': { code: '1', text: 'Rouille' },
  '2': { code: '2', text: 'Pièce manquante' },
  '3': { code: '3', text: 'Enjoliveur manquant' },
  '4': { code: '4', text: 'Fissure de carrosserie' },
  '5': { code: '5', text: 'Élement déformé' },
  '6': { code: '6', text: 'Élement fracassé' },
  '7': { code: '7', text: 'Bosse' },
  '8': { code: '8', text: 'Rayure' },
  '9': { code: '9', text: 'Lampe brisée' },
}

const partChoicesFR = {
  '0': { code: '0', text: 'Pare-choc arrière' },
  '1': { code: '1', text: 'Pare-choc avant' },
  '2': { code: '2', text: 'Porte ARG' },
  '3': { code: '3', text: 'Porte ARD' },
  '4': { code: '4', text: 'Porte AVG' },
  '5': { code: '5', text: 'Porte AVD' },
  '6': { code: '6', text: 'Aile ARG' },
  '7': { code: '7', text: 'Aile ARD' },
  '8': { code: '8', text: 'Aile AVG' },
  '9': { code: '9', text: 'Aile AVD' },
  '10': { code: '10', text: 'Anti-brouillard ARG' },
  '11': { code: '11', text: 'Anti-brouillard ARD' },
  '12': { code: '12', text: 'Anti-brouillard AVG' },
  '13': { code: '13', text: 'Anti-brouillard AVD' },
  '14': { code: '14', text: 'Grille' },
  '15': { code: '15', text: 'Poignée ARG' },
  '16': { code: '16', text: 'Poignée ARD' },
  '17': { code: '17', text: 'Poignée AVG' },
  '18': { code: '18', text: 'Poignée AVD' },
  '19': { code: '19', text: 'Phare AVG' },
  '20': { code: '20', text: 'Phare AVD' },
  '21': { code: '21', text: 'Support Phare' },
  '22': { code: '22', text: 'Capot' },
  '23': { code: '23', text: 'Enjoliveur ARG' },
  '24': { code: '24', text: 'Enjoliveur ARD' },
  '25': { code: '25', text: 'Enjoliveur AVG' },
  '26': { code: '26', text: 'Enjoliveur AVD' },
  '27': { code: '27', text: 'Clignotant gauche' },
  '28': { code: '28', text: 'Clignotant droit' },
  '29': { code: '29', text: 'Plaque arrière' },
  '30': { code: '30', text: 'Plaque avant' },
  '31': { code: '31', text: 'Logo' },
  '32': { code: '32', text: 'Support de Rétroviseur' },
  '33': { code: '33', text: 'Rétroviseur gauche' },
  '34': { code: '34', text: 'Rétroviseur droit' },
  '35': { code: '35', text: 'Montant de pavillon' },
  '36': { code: '36', text: 'Custode ARG' },
  '37': { code: '37', text: 'Custode ARD' },
  '38': { code: '38', text: 'Custode AVG' },
  '39': { code: '39', text: 'Custode AVD' },
  '40': { code: '40', text: 'Bas de caisse' },
  '41': { code: '41', text: 'Toit' },
  '42': { code: '42', text: 'Feu ARG' },
  '43': { code: '43', text: 'Feu ARD' },
  '44': { code: '44', text: 'Coffre' },
  '45': { code: '45', text: 'Roue ARG' },
  '46': { code: '46', text: 'Roue ARD' },
  '47': { code: '47', text: 'Roue AVG' },
  '48': { code: '48', text: 'Roue AVD' },
  '49': { code: '49', text: 'Fenêtre ARG' },
  '50': { code: '50', text: 'Fenêtre ARD' },
  '51': { code: '51', text: 'Fenêtre AVG' },
  '52': { code: '52', text: 'Fenêtre AVD' },
  '53': { code: '53', text: 'Pare-Brise arrière' },
  '54': { code: '54', text: 'Pare-Brise avant' },
  '55': { code: '55', text: 'Essuie-glaces' },
}

const deviceLanguage = getLocalLanguage()

export const partChoices =
  deviceLanguage === 'fr' ? partChoicesFR : partChoicesEN

export const damageChoices =
  deviceLanguage === 'fr' ? damageChoicesFR : damageChoicesEN

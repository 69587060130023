import React, { useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Main from '@/debug/Main';

import { useImage } from '@/hooks/useImage';
import { useAddDamage } from '@/components/Modals/AddDamage/useAddDamage';

import { rectangleToBbox } from '@/services/dataModel/bbox';

import Button from '@/components/Button'
import Modal from '@/components/Modals'
import SelectField from '@/components/SelectField'
import { partChoices, damageChoices } from '@/constants/partAndDamages'

type Props = {
  isOpen: boolean
  handleClose: any
  imageApiId: number | string
  partViewsInImage: any
  selectedImage: Image
}

const AddDamage = ({
  isOpen,
  handleClose,
  imageApiId,
  partViewsInImage,
  selectedImage,
}: Props) => {
  const { hasLoaded, loadedImage } = useImage(selectedImage.url);
  const height = loadedImage?.naturalHeight ?? 100;
  const width = loadedImage?.naturalWidth ?? 100;
  const { innerWidth: vpw, innerHeight: vph} = window;
  const imageRatio = Math.min((vpw - 200) / width, (vph - 200) / height)

  // i am not sure we use this scale ratio as intended
  const scale = imageRatio

  // width of viewport - (width margin) TODO put in constant
  const stageWidth = vpw - 500
  // height of viewport - (height margin) TODO put in constant
  const stageHeight = vph - 300
  
  const { turnaroundId } = useParams<{ turnaroundId: string }>()
  const [selectedRectangle, setSelectedRectangle] = useState<Maybe<Rectangle>>(null);
  const {
    visiblePartTypes,
    partTypeChoice,
    setPartTypeChoice,
    damageTypeChoice,
    setDamageTypeChoice,
    onAddDamage
  } = useAddDamage(partViewsInImage, imageApiId, turnaroundId, handleClose, rectangleToBbox(selectedRectangle, scale));

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} maxWidth="lg">
      <div>
        <div style={{ textAlign: 'center', fontSize: 25, marginBottom: 40 }}>
          Add a damage
        </div>
        <div style={{ textAlign: 'center', fontSize: 20, marginBottom: 40 }}>
          Please add only the damages you can see on the image
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 2, marginLeft: 30 }}>
            { hasLoaded &&
            <Main
              imageUri={selectedImage.url}
              style={{
                width: stageWidth,
                height: stageHeight,
              }}
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              imageWidth={width * imageRatio}
              imageHeight={height * imageRatio}
              isDrawing
              setSelectedRectangle={setSelectedRectangle}
            />
            }
          </div>
          <div style={{ flex: 1, marginLeft: 30 }}>
            <div style={{ marginTop: 20 }} />
            <SelectField
              choices={_.filter(partChoices, (partChoice) =>
                visiblePartTypes.includes(Number(partChoice.code))
              )}
              onChange={(_tag, value) => setPartTypeChoice(value)}
              tag="partType"
              value={partTypeChoice}
              label="Part type"
            />
            <div style={{ marginTop: 20 }} />
            <SelectField
              choices={_.map(damageChoices)}
              onChange={(_tag, value) => setDamageTypeChoice(value)}
              tag="damageType"
              value={damageTypeChoice}
              label="Damage type"
            />
            <div style={{ marginTop: 20 }} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 30,
            justifyContent: 'flex-end',
            marginRight: 100,
          }}
        >
          <Button
            label="Confirm"
            onClick={onAddDamage}
            style={{ width: 200 }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AddDamage

import Turnaround from '@/pages/Turnaround'
import _ from 'lodash'

declare global {
  interface BBox {
    originX: number
    originY: number
    sizeX: number
    sizeY: number
  }

  interface DamageView {
    apiId: number | string
    bbox?: BBox
    damageType: number
    partType: number
    isAlgoGenerated: boolean
    isDeleted: boolean
    isValidated?: boolean
    miniatureUrl?: string
    partViewApiId: number
  }

  type DamageViewWithIndex = DamageView & { index: number; imageIndex: number }

  interface PartView {
    apiId: number
    bbox: BBox
    partType: number
    miniatureUrl?: string
  }

  interface Image {
    apiId: number
    url: string
    damageViews: { [apiId: string]: DamageView }
    partViews: { [apiId: string]: PartView }
  }

  interface LocalImage {
    deviceOrientation?: number
    height?: number
    width?: number
    uri?: string
    url?: string
  }

  interface PartDamage {
    partType: number
    damageType: number
  }

  type Maybe<T> = T | null

  interface Turnaround {
    id: string
    version?: number
    predictionsByViews: { [apiId: string]: Image }
    hasBeenSynchronized?: boolean
    hasBeenSentToAPI?: boolean
    backgroundImage?: LocalImage
    vinOrRegistering?: string
    dateOfCreation: Date
    dateOfPredictionReception?: Date
    dateOfValidation?: Date
    cleanliness: string

    // in a new verrsion, rm this comment when migrattion is done
    vin?: Maybe<string>
    registering?: Maybe<string>
    model?: Maybe<string>
    brand?: Maybe<string>
  }
}

export const getImagesWithCar = (images: { [apiId: string]: Image }) => {
  return _.pickBy(images, (image) => _.size(image.partViews) > 0)
}

export const getIsImageWithCar = (image: Image) => {
  return !_.isEmpty(image.partViews)
}

export const areAllImagesValidated = (images: Array<Image>) => {
  const damageViews = _.flatMap(images, (image) => _.values(image.damageViews))
  const isAllValidated = _.every(damageViews, (damageView) =>
    Boolean(damageView?.isValidated)
  )
  return isAllValidated
}

interface DamagesCount {
  total: number
  nonDeleted: number
}

export const getDamagesCount = (turnaround: Turnaround): DamagesCount => {
  const damageViews = _.flatMap(turnaround.predictionsByViews, (item: Image) =>
    _.map(item.damageViews)
  )

  const validatedDamagesNumber = _.size(
    _.filter(damageViews, (item: DamageView) => !item.isDeleted)
  )

  return {
    total: _.size(damageViews),
    nonDeleted: validatedDamagesNumber,
  }
}

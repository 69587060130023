// @flow
import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { PAGES } from '@/constants/routes'
import useUser from '@/hooks/useUser'

// TODO: move this to a HOC please
export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const user = useUser()
  const isLoggedIn = Boolean(user)

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: PAGES.LOGIN,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute

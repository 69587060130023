import React, { useRef } from 'react';
import { Stage, Layer } from 'react-konva';

import { useScale } from './hooks/useScale';
import { useDrawRectangle } from './hooks/useDrawRectangle';

import { areRectanglesEqual } from './utils';
import Image from './components/Image';
import Rect from './components/Rect';

type Props = {
  imageUri: string,
  style?: Object,
  stageWidth?: number,
  stageHeight?: number,
  imageWidth:number,
  imageHeight:number,
  initialBoundingBoxes?: Rectangle[],
  isDrawing?: boolean,
  zoomTo?: Rectangle | null,
  setSelectedRectangle?: (rect: Rectangle) => void,
};

function Main({
  imageUri, 
  style, 
  stageWidth = 1000,
  stageHeight = 1000,
  imageWidth,
  imageHeight,
  initialBoundingBoxes = [], 
  isDrawing = false, 
  zoomTo,
  setSelectedRectangle,
}: Props) {
  const stageRef = useRef<any>();
  const layerRef = useRef<any>();
  const newScale = 3;
  const {
    stageX, stageY, stageScale, handleWheel,
  } = useScale(imageUri, newScale, layerRef.current, zoomTo);
  const {
    annotations,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    selectAnnotation,
    handleKeyDown,
    selectedId,
  } = useDrawRectangle(initialBoundingBoxes, setSelectedRectangle, isDrawing, layerRef.current);

  return (
    <div
      style={{ ...style, outline: 'none', backgroundColor: '#F5F5F5', borderRadius: 5, overflow: 'hidden', display:'flex' }}
      onKeyDown={handleKeyDown}
      tabIndex={1}
    >
      <Stage
        ref={stageRef}
        width={stageWidth}
        height={stageHeight}
        onWheel={handleWheel}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stageX}
        y={stageY}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer draggable={!isDrawing} ref={layerRef}>
          <Image
            src={imageUri}
            onMouseDown={() => {
              // deselect when clicked on empty area
              selectAnnotation(null);
            }}
            width={imageWidth} // try to change this
            height={imageHeight} // try to change this (add a prop)
          />
          {annotations.map((value, index) => {
            return (
              <Rect
                key={index}
                index={index}
                value={value}
                selectAnnotation={() => selectAnnotation(index)}
                isSelected={selectedId === index}
                isDrawing={isDrawing}
                stroke={areRectanglesEqual(value, zoomTo) ? "blue" : undefined}
                stageScale={stageScale}
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
}

export default Main;

import { getMessage } from '@/translations'
import { damageTypeToCode } from '@/constants/partAndDamages'
import { LanguageKey } from '@/translations/messages'

export const getDamageMessage = (damageType: number) => {
  const damageTypeMessage = getMessage(
    `DAMAGE_TYPE.${
      damageTypeToCode[
        damageType?.toString() as keyof typeof damageTypeToCode
      ]
    }` as LanguageKey
  )
  return damageTypeMessage;
};

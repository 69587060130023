// @flow

import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router-dom'

import { PAGES } from '@/constants/routes'
import { selectAuthentication } from '@/pages/Login/reducer'

interface User {
  id: number
  organization: string
}

const useUser = () => {
  const token = useSelector(selectAuthentication)
  const history = useHistory()
  if (!token) {
    history.replace(PAGES.LOGIN)
    return
  }
  const loggedUser = jwtDecode(token)
  return loggedUser as User
}

export default useUser

import { useState } from 'react';
import useAlgolia from 'use-algolia';

import useUser from '@/hooks/useUser';

const useSearchTurnaround = () => {
  const user = useUser();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [searchState, requestDispatch] = useAlgolia(
    process.env.REACT_APP_ALGOLIA_APP_ID || '',
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY || '',
    'turnarounds',
    { hitsPerPage: 100, query, filters: `userId:${user?.id}` }
  );
  const { loading } = searchState;
  const searchedTurnarounds = searchState.hits as Turnaround[];
  const onChange = (args: string) => {
    setQuery(args);
    requestDispatch({ query: args });
  }
  return {
    query,
    onChange,
    isLoading: loading,
    searchedTurnarounds,
  };
};

export default useSearchTurnaround;

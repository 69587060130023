import React from 'react'
import _ from 'lodash';
import Main from '@/debug/Main';

import { bboxToRectangle } from '@/services/dataModel/bbox';
import { useImage } from '@/hooks/useImage';


type Props = {
  selectedImage: Image
  selectedDamageView: DamageView | null
  damageViewIndex: number | null
  hasTurnaroundBeenValidated: boolean
}

const MiddleComponent = ({
  selectedImage,
  selectedDamageView,
  damageViewIndex,
}: Props) => {
  const { hasLoaded, loadedImage } = useImage(selectedImage.url);
  const height = loadedImage?.naturalHeight ?? 100;
  const width = loadedImage?.naturalWidth ?? 100;
  const { innerWidth: vpw, innerHeight: vph} = window;
  const imageRatio = Math.min(vpw / width, vph / height)
  
  // i am not sure we use this scale ratio as intended
  const scale = imageRatio
  
  // width of viewport - (width component + margin of 10) TODO put in constant
  const stageWidth = vpw - 260
  // height of viewport - (footer height component + header component + margin of 10) TODO put in constant
  const stageHeight = vph - 180

  const bboxes: Rectangle[] = _.map(
    selectedImage.damageViews,
    (damageView, index: number) => bboxToRectangle(damageView.bbox, index, scale),
  ) as any;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      {hasLoaded && 
      <Main
        imageUri={selectedImage.url}
        style={{
          // width of viewport - (width component + margin of 10) TODO put in constant
          width: stageWidth,
          // height of viewport - (footer height component + header component + margin of 10) TODO put in constant
          height: stageHeight,
        }}
        stageWidth={stageWidth}
        stageHeight={stageHeight}
        imageWidth={width * imageRatio}
        imageHeight={height * imageRatio}
        initialBoundingBoxes={_.compact(bboxes)}
        isDrawing={false}
        zoomTo={bboxToRectangle(selectedDamageView?.bbox, damageViewIndex, scale)}
      />
      }
    </div>
  )
}

export default MiddleComponent

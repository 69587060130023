import _ from 'lodash';

export const bboxToRectangle = (bbox: BBox | null | undefined, index: number | null, scale: number | null) => {
  if (bbox && scale && !_.isNil(index)) {
    return {
      x: bbox.originX * scale,
      y: bbox.originY * scale,
      width: bbox.sizeX * scale,
      height: bbox.sizeY * scale,
      key: index,
    };
  }
  return null;
};

export const rectangleToBbox = (rectangle: Rectangle | null | undefined, scale: number | null) => {
  if (rectangle && scale) {
    return {
      originX: rectangle.width > 0 ? rectangle.x / scale : (rectangle.x + rectangle.width) / scale,
      originY: rectangle.height > 0 ? rectangle.y / scale : (rectangle.y + rectangle.height) / scale,
      sizeX: Math.abs(rectangle.width) / scale,
      sizeY: Math.abs(rectangle.height) / scale,
    };
  }
  return null;
};

import { useEffect, useState } from 'react';

const useImage = (src: string) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasStartedInitialFetch, setHasStartedInitialFetch] = useState(false);
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
      setHasStartedInitialFetch(true);
      setHasLoaded(false);
      setHasError(false);

      const image = new Image();
      image.src = src;

      const handleError = () => {
          setHasError(true);
      };

      const handleLoad = () => {
          setHasLoaded(true);
          setHasError(false);
          setLoadedImage(image);
      };

      image.onerror = handleError;
      image.onload = handleLoad;

      return () => {
          image.removeEventListener("error", handleError);
          image.removeEventListener("load", handleLoad);
      };
  }, [src]);

  return { hasLoaded, hasError, hasStartedInitialFetch, loadedImage };
};

export { useImage };

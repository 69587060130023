import React from 'react'
import MuiButton from '@material-ui/core/Button'

import { COLORS } from '@/constants/design'
import ActivityIndicator from '@/components/ActivityIndicator'

type Props = {
  isDisabled?: boolean
  onClick: () => void
  label: string
  isLoading?: boolean
  icon?: React.ReactNode | null
  style?: Object
  type?: 'button' | 'reset' | 'submit' | undefined
  isOulined?: boolean
  isSecondary?: boolean
}

const Button = ({
  isDisabled,
  onClick,
  label,
  isLoading,
  icon,
  style,
  type,
  isOulined,
  isSecondary,
}: Props) => {
  return (
    <MuiButton
      color={isSecondary ? 'secondary' : 'primary'}
      variant={isOulined ? 'outlined' : 'contained'}
      disabled={isDisabled}
      onClick={onClick}
      style={style}
      type={type}
    >
      {icon}
      {isLoading ? (
        <ActivityIndicator color={COLORS.BLUE} size={21} />
      ) : (
        label && <span>{label}</span>
      )}
    </MuiButton>
  )
}

export default Button

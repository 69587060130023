import { useState, useEffect } from 'react';

const getAbsoluteCoordinate = (layer: any, point: { x: number, y: number }) => {
  const transform = layer.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();
  return transform.point(point);
};

export const useDrawRectangle = (initialBoundingBoxes: Rectangle[], setSelectedRectangle: any, isDrawing: boolean, layer:any) => {
  const [annotations, setAnnotations] = useState<Rectangle[]>(initialBoundingBoxes);
  useEffect(() => {
    setAnnotations(initialBoundingBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialBoundingBoxes)]);
  const [newAnnotation, setNewAnnotation] = useState<Rectangle[]>([]);
  const [selectedId, selectAnnotation] = useState<number | null>(null);

  const handleMouseDown = (event: any) => {
    if (isDrawing && selectedId === null && newAnnotation.length === 0) {
      const stage = event.target.getStage();
      const initialPoint = stage.getPointerPosition();

      if (!layer){
        return
      }

      const { x, y } = getAbsoluteCoordinate(layer, initialPoint);

      setNewAnnotation([{ x, y, width: 0, height: 0, key: 0 }]);
    }
  };
  const handleMouseUp = (event: any) => {
    if (isDrawing && selectedId === null && newAnnotation.length === 1) {
      const stage = event.target.getStage();
      const movingPoint = stage.getPointerPosition();
      const { x, y } = getAbsoluteCoordinate(layer, movingPoint);
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const annotationToAdd = {
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: annotations.length + 1
      };
      annotations.push(annotationToAdd);
      setNewAnnotation([]);
      setAnnotations(annotations);
    }
  };

  const handleMouseMove = (event: any) => {
    if (isDrawing && selectedId === null && newAnnotation.length === 1) {
      const stage = event.target.getStage();
      const finalPoint = stage.getPointerPosition();
      const { x, y } = getAbsoluteCoordinate(layer, finalPoint);
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const newRectangle = {
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: 0,
      };
      setNewAnnotation([newRectangle]);
      if (setSelectedRectangle) setSelectedRectangle(newRectangle);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (selectedId !== null) {
        const newAnnotations = annotations.filter(
          (_annotation, index) => index !== selectedId
        );
        setAnnotations(newAnnotations);
      }
    }
  };

  return {
    annotations: [...annotations, ...newAnnotation],
    setAnnotations,
    handleKeyDown,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    selectAnnotation,
    selectedId,
  };
};

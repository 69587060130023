import { useEffect, useState } from 'react';

const useLoadImage = (src: string) => {
  const [image, setImage] = useState<any>(null);
  useEffect(
    () => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => setImage(img);
    },
    [src]
  );
  return image;
};

export { useLoadImage };

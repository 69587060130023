import {
  SET_AUTHENTICATION,
  LOGOUT,
  setAuthentication,
  logout,
} from '@/pages/Login/actions'
import { RootState } from '@/store'

type AuthReducer = { token: string | null }
type Action = ReturnType<typeof setAuthentication | typeof logout>

export const initialState = {
  token: null,
}

const authenticationReducer = (
  state: AuthReducer = initialState,
  action: Action
) => {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return { ...state, token: action.token }
    case LOGOUT:
      return { ...state, token: null }
    default:
      return state
  }
}

export default authenticationReducer

export const selectAuthentication = (state: RootState) =>
  state.authentication.token

import * as React from 'react';
import { Rect, Transformer } from 'react-konva';

type Props = {
  value: {
    x: number,
    y: number,
    width: number,
    height: number,
  },
  selectAnnotation: Function,
  index: number,
  isSelected: boolean,
  isDrawing: boolean,
  stroke?: string,
  stageScale: number,
};

const RectAnnotation = ({ value, selectAnnotation, index , isSelected, isDrawing, stageScale, stroke }: Props) => {
  const shapeRef = React.useRef<any>();
  const transformRef = React.useRef<any>();

  React.useEffect(() => {
    if (isSelected && isDrawing) {
      // we need to attach transformer manually
      transformRef.current.setNode(shapeRef.current);
      transformRef.current.getLayer().batchDraw();
    }
  }, [isSelected, isDrawing]);

  const onMouseEnter = (event: any) => {
    event.target.getStage().container().style.cursor = "move";
  };

  const onMouseLeave = (event: any) => {
    event.target.getStage().container().style.cursor = "crosshair";
  };

  return (
    <>
      <Rect
        x={value.x}
        y={value.y}
        ref={shapeRef}
        width={value.width}
        height={value.height}
        fill="transparent"
        stroke={stroke || "#FF0000"}
        strokeWidth={2/stageScale}
        draggable={isDrawing}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={() => selectAnnotation(index)}
      />
      {isSelected && isDrawing && <Transformer ref={transformRef} />}
    </>
  );
}

export default RectAnnotation;

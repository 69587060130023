import { combineReducers, applyMiddleware } from 'redux'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'

import authReducer from '@/pages/Login/reducer'

export const rootReducer = combineReducers({
  authentication: authReducer,
})
export type RootState = ReturnType<typeof rootReducer>

const enhancer = composeWithDevTools({
  name: 'Harold',
  trace: true,
  traceLimit: 25,
})(applyMiddleware())

// Redux store WHITELIST
const persistConfig = {
  blacklist: [], // useless now
  key: 'root',
  storage,
  whitelist: ['authentication'], // only navigation will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

function configureStore(initialState: any) {
  const store: any = createStore(persistedReducer, initialState, enhancer)
  return store
}

export const store = configureStore({})
export const persistor = persistStore(store)

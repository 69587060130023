import * as React from 'react';

import Button from '@/components/Button'
import KeyValue from '@/components/KeyValue';
import EditTurnaround from '@/components/Modals/EditTurnaround'

type Props = {
  turnaround: Turnaround,
};

const escapeString = (value: string | null | undefined) => value ? value : '-';

const TurnaroundMetaData = ({ turnaround }: Props) => {
  const {
    brand,
    cleanliness,
    dateOfValidation,
    model,
    registering,
    vin,
  } = turnaround;
  const [isEditTurnaroundModalOpen, setIsEditTurnaroundModalOpen] = React.useState(false)
  return (
    <>
      <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
        {!dateOfValidation && <Button
          label="Edit"
          onClick={() => setIsEditTurnaroundModalOpen(true)}
          isOulined
          style={{ width: 100, alignSelf: 'flex-end', marginBottom: 10 }}
        />}
        <KeyValue label="Brand" value={escapeString(brand)} />
        <KeyValue label="Cleanliness" value={escapeString(cleanliness)} />
        <KeyValue label="Model" value={escapeString(model)} />
        <KeyValue label="Registering" value={escapeString(registering)} />
        <KeyValue label="Vin" value={escapeString(vin)} />
      </div>
      <EditTurnaround
        isOpen={isEditTurnaroundModalOpen}
        handleClose={() => setIsEditTurnaroundModalOpen(false)}
        turnaround={turnaround}
      />
    </>
  );
}

export default TurnaroundMetaData;

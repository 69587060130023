import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getMessage } from '@/translations'

type Props = {
  value: string | undefined,
  onChange: (arg0: string) => void,
  loading: boolean | undefined
};

const SearchBar = ({ value, onChange, loading }: Props) => {
  return (
    <Autocomplete
      freeSolo
      disableClearable
      // @ts-ignore
      options={[].map((option) => option.title)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={getMessage('Home.search')}
          margin="normal"
          variant="outlined"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            type: 'search',
          }}
        />
      )}
    />
  );
}

export default SearchBar;

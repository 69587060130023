// @flow
import TextField from '@material-ui/core/TextField'

import styled from 'styled-components'

import { COLORS } from '@/constants/design'

// @ts-ignore
const StyledTextField = styled(TextField)`
  > div {
    border: 1px solid !important;
    border-color: !important;
    :hover {
      border: ${(props) =>
        props.disabled ? 'none' : `1px solid ${COLORS.BLUE} !important`};
    }
    input {
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
      border: none !important;
    }
    textArea {
      border: none !important;
      padding: 0 !important;
      height: 84px !important;
    }
    fieldset {
      border: none;
    }
    input::placeholder {
      opacity: 1;
      color: !important;
      font-weight: 400;
      text-transform: none;
    }
  }
`

export default StyledTextField

import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getMessage } from '@/translations'
import { FONT_FAMILIES } from '@/constants/design'
import { PAGES } from '@/constants/routes'
import { setAuthentication } from '@/pages/Login/actions'

import Card from '@/components/Card'
import Button from '@/components/Button'
import TextField from '@/components/TextField'

const styles: StyleSheets = {
  card: {
    paddingBottom: 30,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    marginTop: 100,
  },
  login: {
    fontSize: 30,
    marginBottom: 20,
    fontFamily: FONT_FAMILIES.DEFAULT,
  },
  loginCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  subTitle: {
    fontSize: 20,
    fontFamily: FONT_FAMILIES.DEFAULT,
  },
}

const Login = () => {
  const [email, setEmail] = useState<string | null | undefined | number>(null)
  const [password, setPassword] = useState<string | null | undefined | number>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const onPressLogin = async (event: any) => {
    event.preventDefault()
    // TODO: use a good fetch library and reuse the hook
    setIsLoading(true)
    const MONK_API_URL = process.env.REACT_APP_MONK_API_URL
    const login = await fetch(`${MONK_API_URL}/login`, {
      body: JSON.stringify({ email, password }),
      headers: {
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json',
      },
      method: 'post',
    })
    const res = await login?.json()
    if (res?.token) {
      setIsLoading(false)
      const token = res?.token
      dispatch(setAuthentication({ token }))
      history.replace(PAGES.HOME)
    } else {
      setIsLoading(false)
      enqueueSnackbar('Wrond email or password', { variant: 'error' })
    }
  }
  return (
    <>
      <div style={styles.container}>
        <div style={styles.login}>{getMessage('Login.title')}</div>
        <div style={styles.subTitle}>{getMessage('Login.subtitle')}</div>
      </div>
      <div style={styles.loginCard}>
        <Card style={styles.card}>
          <form onSubmit={onPressLogin}>
            <TextField
              value={email}
              label={getMessage('Login.email')}
              onChange={(_tag, value) => setEmail(value)}
              style={{ width: 400 }}
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              value={password}
              label={getMessage('Login.password')}
              onChange={(_tag, value) => setPassword(value)}
              type="password"
              style={{ width: 400 }}
            />
            <div style={{ marginTop: 20 }} />
            <Button
              onClick={() => {}} // the onsubmit is handled by form and not
              label={getMessage('Login.submit')}
              style={{ width: 400 }}
              isLoading={isLoading}
              type="submit"
            />
          </form>
        </Card>
      </div>
    </>
  )
}

export default Login

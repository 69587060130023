import _ from 'lodash'
import * as React from 'react'
import NotInterested from '@material-ui/icons/NotInterested'

import GroupedImageView from '@/components/GroupedImages/GroupedImageView'

const styles: StyleSheets = {
  damage: {
    fontWeight: 'bold',
    marginBottom: 5,
    marginLeft: 5,
  },
  images: {
    cursor: 'pointer',
    marginLeft: 20,
    padding: 5,
    paddingLeft: 10,
  },
}

type Props = {
  damageViews: DamageViewWithIndex[]
  images: Array<any>
  imageIndex: number
  setImageIndex: (index: number) => void
}

const GroupedImages = ({
  damageViews,
  images,
  imageIndex,
  setImageIndex,
}: Props) => {
  const activeDamageViewIndexStyle = {
    backgroundColor: '#C9E7F7',
    borderRadius: 5,
  }
  const imageWithIndex = _.map(images, (image, index) => ({ ...image, index }))
  const imagesWithoutDamage = _.filter(
    imageWithIndex,
    (image: Image) =>
      _.isEmpty(image.damageViews) ||
      _.some(image.damageViews, (item) => item.isDeleted)
  )

  const filteredDamageViews = _.uniqBy(
    _.filter(damageViews, 'isValidated'),
    (damageView) => `${damageView.damageType} - ${damageView.partType}`
  )
  const icon = <NotInterested fontSize="small" htmlColor="black" />
  const hasImageWihtoutdamage = _.size(imagesWithoutDamage) > 0
  return (
    <div>
      {_.map(filteredDamageViews, (damageView, index) => (
        <GroupedImageView
          key={index}
          damageView={damageView}
          images={images}
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
        />
      ))}
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <div>{icon}</div>
        <div style={styles.damage}>No damage</div>
      </div>
      {_.map(imagesWithoutDamage, ({ index }) => (
        <div
          key={index}
          style={
            index === imageIndex
              ? { ...styles.images, ...activeDamageViewIndexStyle }
              : styles.images
          }
          onClick={() => setImageIndex(index)}
        >
          image - {index + 1}
        </div>
      ))}
      {!hasImageWihtoutdamage && (
        <div style={styles.images}>
          {/* TODO: sentence bellow should be added to translations */}
          No image without damage
        </div>
      )}
    </div>
  )
}

export default GroupedImages

import { useState } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack'

import { addDamage } from '@/services/firestore/turnarounds'
import generateUniqueId from '@/services/uniqueId'

export const useAddDamage = (
  partViewsInImage: any, imageApiId: number | string, turnaroundId: string, handleClose: any, bbox: Maybe<BBox>,
) => {
  const [partTypeChoice, setPartTypeChoice] = useState<Maybe<{ code: string; text: string }>>(null);
  const [damageTypeChoice, setDamageTypeChoice] = useState<Maybe<{ code: string; text: string }>>(null);
  const { enqueueSnackbar } = useSnackbar()
  const visiblePartTypes = _.map(partViewsInImage, (partView) => Number(partView.partType));
  const onAddDamage = async () => {
    if (!partTypeChoice || !damageTypeChoice) {
      return enqueueSnackbar('Please fill all mandatory fields', {
        variant: 'error',
      });
    }

    const partView: PartView = _.find(
      partViewsInImage,
      (partView) => Number(partView.partType) === Number(partTypeChoice.code)
    )
    
    handleClose() // optimistic answer is key
    await addDamage({
      imageApiId,
      bbox,
      damageViewId: generateUniqueId(),
      damageType: damageTypeChoice.code,
      partType: partTypeChoice.code,
      partViewId: partView.apiId.toString(),
      turnaroundId,
    })
    // reset state
    setPartTypeChoice(null)
    setDamageTypeChoice(null)
    // end of reset state
    enqueueSnackbar('The damage has been added', { variant: 'success' })
  }
  return {
    visiblePartTypes,
    partTypeChoice,
    setPartTypeChoice,
    damageTypeChoice,
    setDamageTypeChoice,
    onAddDamage
  };
};

const translations = {
  'EditTurnAround.cleanliness.clean': 'Propre',
  'EditTurnAround.cleanliness.dirty': 'Sale',
  'EditTurnAround.cleanliness.wet': 'Mouillé',
  'Home.title': 'Accueil',
  'Home.search': 'Chercher par modele, marque, propreté, etc',
  'Home.Logout': 'Se déconnecter',
  'Login.title': 'Se connecter',
  'Login.subtitle': "Système d'inspection basé sur l'IA",
  'Login.email': 'Email',
  'Login.password': 'Mot de passe',
  'Login.submit': 'Se connecter',
  'TurnaroundItem.seeMore': 'Voir plus',
  'Turnaround.damages': 'dommages',
  'TurnaroundItem.predictionNotReady': 'En attente de prédiction',
  'TurnaroundItem.notACar':
    'Vous ne pouvez pas ajouter de dommage sur cette image',
  'Turnaround.title': 'Inspection',
  'Turnaround.noDamage': 'Aucun dommage',

  'DAMAGE_TYPE.body_crack': 'Fissure de carrosserie',
  'DAMAGE_TYPE.broken_glass': 'Bris de glace',
  'DAMAGE_TYPE.broken_light': 'Phare cassé',
  'DAMAGE_TYPE.dent': 'Bosse',
  'DAMAGE_TYPE.hubcap_scratch': 'Enjoliveur rayé',
  'DAMAGE_TYPE.misshape': 'Elément déformé',
  'DAMAGE_TYPE.missing_hubcap': 'Enjoliveur manquant',
  'DAMAGE_TYPE.missing_piece': 'Elément manquant',
  'DAMAGE_TYPE.paint_peeling': 'Peinture écaillée',
  'DAMAGE_TYPE.rustiness': 'Rouille',
  'DAMAGE_TYPE.scattered_scratches': 'Projections',
  'DAMAGE_TYPE.scratch': 'Rayure',
  'DAMAGE_TYPE.smash': 'Element fracassé',
  'PART_TYPE.bumper_back': 'Pare-choc arrière',
  'PART_TYPE.bumper_front': 'Pare-choc avant',
  'PART_TYPE.door_back_left': 'Porte ARG',
  'PART_TYPE.door_back_right': 'Porte ARD',
  'PART_TYPE.door_front_left': 'Porte AVG',
  'PART_TYPE.door_front_right': 'Porte AVD',
  'PART_TYPE.fender_back_left': 'Aile ARG',
  'PART_TYPE.fender_back_right': 'Aile ARD',
  'PART_TYPE.fender_front_left': 'Aile AVG',
  'PART_TYPE.fender_front_right': 'Aile AVD',
  'PART_TYPE.Fog lights': 'Anti-brouillards',
  'PART_TYPE.fog_light_back_left': 'Anti-brouillards ARG',
  'PART_TYPE.fog_light_back_right': 'Anti-brouillards ARD',
  'PART_TYPE.fog_light_front_left': 'Anti-brouillards AVG',
  'PART_TYPE.fog_light_front_right': 'Anti-brouillards AVD',
  'PART_TYPE.grill': 'Grille',
  'PART_TYPE.grill_low': 'Grille de calandre',
  'PART_TYPE.grill_radiator': 'Grille de radiateur',
  'PART_TYPE.handle_back_left': 'Poignée ARG',
  'PART_TYPE.handle_back_right': 'Poignée ARD',
  'PART_TYPE.handle_front_left': 'Poignée AVG',
  'PART_TYPE.handle_front_right': 'Poignée AVD',
  'PART_TYPE.head_light_left': 'Phare AVG',
  'PART_TYPE.head_light_right': 'Phare AVD',
  'PART_TYPE.header_panel': 'Paroie Avant',
  'PART_TYPE.hood': 'Capot',
  'PART_TYPE.hook': 'Boule',
  'PART_TYPE.hubcap_back_left': 'Enjoliveur ARG',
  'PART_TYPE.hubcap_back_right': 'Enjoliveur ARD',
  'PART_TYPE.hubcap_front_left': 'Enjoliveur AVG',
  'PART_TYPE.hubcap_front_right': 'Enjoliveur AVD',
  'PART_TYPE.indicator_light_left': 'indicator_light_left',
  'PART_TYPE.indicator_light_right': 'indicator_light_right',
  'PART_TYPE.license_plate_back': "Plaque d'immat. arrière",
  'PART_TYPE.license_plate_front': "Plaque  d'immat. avant",
  'PART_TYPE.logo': 'Logo',
  'PART_TYPE.mirror_left': 'Rétroviseur gauche',
  'PART_TYPE.mirror_right': 'Rétroviseur droit',
  'PART_TYPE.mirror_support': 'Support de Rétroviseur',
  'PART_TYPE.Other lights': 'Autres feux',
  'PART_TYPE.petrol_door': 'Trappe essence',
  'PART_TYPE.pillar': 'Montant de pavillon',
  'PART_TYPE.quarter_window_back_left': 'Custode ARG',
  'PART_TYPE.quarter_window_back_right': 'Custode ARD',
  'PART_TYPE.quarter_window_front_left': 'Custode AVG',
  'PART_TYPE.quarter_window_front_right': 'Custode AVD',
  'PART_TYPE.rear_spoiler': 'Aileron arrière',
  'PART_TYPE.rocker_panel': 'Bas de caisse',
  'PART_TYPE.rocker_panel_left': 'Bas de caisse gauche',
  'PART_TYPE.rocker_panel_right': 'Bas de caisse droit',
  'PART_TYPE.roof': 'Toit',
  'PART_TYPE.tail_light_center': 'Feu Stop central',
  'PART_TYPE.tail_light_left': 'Phare ARG',
  'PART_TYPE.tail_light_right': 'Phare ARD',
  'PART_TYPE.trunk': 'Coffre',
  'PART_TYPE.turn_signal_front_lateral_left': 'Clignotant latéral AVG',
  'PART_TYPE.turn_signal_front_lateral_right': 'Clignotant latéral AVD',
  'PART_TYPE.wheel_back_left': 'Roue ARG',
  'PART_TYPE.wheel_back_right': 'Roue ARD',
  'PART_TYPE.wheel_front_left': 'Roue AVG',
  'PART_TYPE.wheel_front_right': 'Roue AVD',
  'PART_TYPE.window_back_left': 'Fenêtre ARG',
  'PART_TYPE.window_back_right': 'Fenêtre ARD',
  'PART_TYPE.window_corner_left': 'Coin de fenêtre gauche',
  'PART_TYPE.window_corner_right': 'Coin de fenêtre droit',
  'PART_TYPE.window_front_left': 'Fenêtre AVG',
  'PART_TYPE.window_front_right': 'Fenêtre AVD',
  'PART_TYPE.windshield_back': 'Lunette arrière',
  'PART_TYPE.windshield_front': 'Pare-brise avant',
  'PART_TYPE.wiper': 'Essuie-glaces',
  'PART_TYPE.wiper_back': 'Essuie-glaces arrière',
  'PART_TYPE.wiper_front': 'Essuie-glaces avant',
}

export type TranslationFr = keyof typeof translations

export default translations

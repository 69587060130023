import * as React from 'react';

const styles: StyleSheets = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 'bold',
  },
  value: {

  },
};

const KeyValue = ({ label, value }: { label: string, value: string | null | undefined }) => {
  return (
    <div style={styles.container}>
      <div style={styles.label}>{label}</div>
      <div style={styles.value}>{value}</div>
    </div>
  );
}

export default KeyValue;

import React from 'react'
import _ from 'lodash'
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler'

const styles: StyleSheets = {
  imageContainer: {
    cursor: 'pointer',
  },
  imagesContainer: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  images: {
    borderRadius: 5,
    maxWidth: 80,
    margin: 2,
  },
}

type Props = {
  images: Array<any>
  imageIndex: number
  setImageIndex: (index: number) => void
}

const LeftComponent = ({
  images,
  imageIndex,
  setImageIndex,
}: Props) => {
  const onPressLeftKey = () => {
    // go back to last image
    if (imageIndex === 0) setImageIndex(_.size(images) - 1)
    // go left
    else setImageIndex((imageIndex - 1) % _.size(images))
  }
  const onPressRightKey = () => {
    setImageIndex((imageIndex + 1) % _.size(images))
  }

  const selectedImageStyle = {
    borderWidth: 5,
    borderStyle: 'solid',
    borderRadius: 5,
    borderColor: 'blue',
  }
  return (
    <div style={styles.imagesContainer}>
      {_.map(images, (image, index) => (
        <div
          style={styles.imageContainer}
          key={index}
          onClick={() => setImageIndex(index)}
        >
          <img
            src={image.url}
            style={
              index === imageIndex
                ? { ...styles.images, ...selectedImageStyle }
                : styles.images
            }
            alt=""
          />
        </div>
      ))}
      <KeyboardEventHandler
        handleKeys={['left', 'right']}
        onKeyEvent={(key: any) => {
          if (key === 'left') onPressLeftKey()
          else onPressRightKey()
        }}
      />
    </div>
  )
}

export default LeftComponent

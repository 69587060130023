import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useSnackbar } from 'notistack'

import useSelectImageAndDamageView from '@/hooks/useSelectDamageView'
import { useGetTurnaround } from '@/hooks/Turnaround'

import { validateDamageView } from '@/services/firestore/turnarounds'

import { getMessage } from '@/translations'
import { areAllImagesValidated } from '@/services/dataModel/turnaround'
import Layout from '@/connectedComponents/Layout'
import ActivityIndicator from '@/components/ActivityIndicator'
import BottomComponent from '@/pages/Turnaround/components/BottomComponent'
import MiddleComponent from '@/pages/Turnaround/components/MiddleComponent'
import RightComponent from '@/pages/Turnaround/components/RightComponent'

const Turnaround = () => {
  const { turnaroundId } = useParams<{ turnaroundId: string }>()
  const { turnaround, loading } = useGetTurnaround(turnaroundId)
  const { enqueueSnackbar } = useSnackbar()
  const images = _.orderBy(
    _.values(turnaround.predictionsByViews),
    'apiId',
    'asc'
  )
  const hasBeenValidated = Boolean(turnaround.dateOfValidation)

  const isAllValidated = areAllImagesValidated(images)
  const damageViewsWithoutIndex = _.flatMap(images, (image, index) =>
    _.map(image.damageViews, (damageView) => ({
      ...damageView,
      imageIndex: index,
    }))
  )

  const damageViews = _.map(damageViewsWithoutIndex, (damageView, index) => ({
    ...damageView,
    index,
  }))
  const {
    damageViewIndex,
    setDamageAndImageIndex,
    imageIndex,
    setImageIndex,
  } = useSelectImageAndDamageView(damageViews)
  if (!turnaround && !loading) throw new Error('turn around not found')
  if (loading)
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator />
      </div>
    )

  const selectedImage = images[imageIndex]
  const { dateOfCreation, vin } = turnaround || {}
  const middleTitle = (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <div style={{ marginLeft: 10 }}>
        {moment(dateOfCreation).format('DD-MM-YYYY HH:mm')} - {vin || 'None'}
      </div>
    </div>
  )
  const selectedDamageView = _.isNil(damageViewIndex)
    ? null
    : damageViews[damageViewIndex]

  const validateOrDeleteDamageView = async (isDeleted: boolean) => {
    if (!_.isNil(damageViewIndex)) {
      if (!selectedDamageView?.isAlgoGenerated) return
      await validateDamageView({
        turnaroundId,
        imageApiId: selectedImage.apiId,
        damageViewApiId: selectedDamageView.apiId,
        isDeleted: isDeleted,
      })
      setDamageAndImageIndex(damageViewIndex + 1)
      enqueueSnackbar('The damage has been saved', { variant: 'success' })
    }
  }

  return (
    <Layout
      leftTitle={getMessage('Turnaround.title')}
      middleTitle={middleTitle}
      withBackArrow
      hideOverFlow
    >
      <Box
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
      >
        <div style={{ height: 70 }} />
        <Box style={{ height: '100%' }}>
          <Box
            style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
          >
            <Box
              style={{ flexGrow: 1, flexDirection: 'column', display: 'flex' }}
            >
              <Box style={{ flexGrow: 1, display: 'flex', height: 100 }}>
                <MiddleComponent
                  selectedImage={selectedImage}
                  selectedDamageView={selectedDamageView}
                  damageViewIndex={damageViewIndex}
                  hasTurnaroundBeenValidated={hasBeenValidated}
                />
              </Box>
              <Box style={{ height: 100, justifyContent: 'center' }}>
                <BottomComponent
                  images={images}
                  imageIndex={imageIndex}
                  setImageIndex={setImageIndex}
                />
              </Box>
            </Box>
            <div style={{}} />
            <Box style={{ width: 250 }}>
              <RightComponent
                damageViewIndex={damageViewIndex}
                setDamageAndImageIndex={setDamageAndImageIndex}
                damageViews={damageViews}
                hasTurnaroundBeenValidated={hasBeenValidated}
                isAllValidated={isAllValidated}
                selectedImage={selectedImage}
                partViewsInImage={selectedImage?.partViews}
                validateOrDeleteDamageView={validateOrDeleteDamageView}
                turnaround={turnaround}
                images={images}
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default Turnaround

import firebase from 'firebase';

import { removeUndefined } from '@/services/firestore/utils';

const firestore = firebase.firestore

export const updateTurnaround = async ({
  vin,
  brand,
  registering,
  cleanliness,
  model,
  turnaroundId,
}: {
  vin?: Maybe<string>
  brand?: Maybe<string>,
  registering?: Maybe<string>
  cleanliness?: Maybe<string>
  model?: Maybe<string>
  turnaroundId: string
}) => {
  // firestore doesn't want undefined
  const metaDataToUpdate = removeUndefined({
    vin,
    brand,
    registering,
    cleanliness,
    model,
  });
  await firestore()
    .collection('turnarounds')
    .doc(turnaroundId)
    .update(metaDataToUpdate)
    .catch((error) => console.error('Error writing document: ', error))
}

export const validateTurnaround = async (turnaroundId: string) => {
  await firestore()
    .collection('turnarounds')
    .doc(turnaroundId)
    .update({
      dateOfValidation: new Date(),
    })
    .catch((error) => console.error('Error writing document: ', error))
}

export const validateDamageView = async ({
  turnaroundId,
  imageApiId,
  damageViewApiId,
  isDeleted,
}: {
  turnaroundId: string
  imageApiId: number
  damageViewApiId: number | string
  isDeleted: boolean
}) => {
  const update: { [key: string]: any } = {}

  update[
    `predictionsByViews.${imageApiId}.damageViews.${damageViewApiId}.isValidated`
  ] = true
  update[
    `predictionsByViews.${imageApiId}.damageViews.${damageViewApiId}.isDeleted`
  ] = isDeleted
  await firestore()
    .collection('turnarounds')
    .doc(turnaroundId)
    .update(update)
    .catch((error) => console.error('Error writing document: ', error))
}

export const addDamage = async ({
  imageApiId,
  bbox,
  damageViewId,
  damageType,
  partType,
  partViewId,
  turnaroundId,
}: {
  imageApiId: string | number
  bbox: Maybe<BBox>
  damageViewId: string
  damageType: string
  partType: string
  partViewId: string
  turnaroundId: string
}) => {
  const update = {
    [`predictionsByViews.${imageApiId}.damageViews.${damageViewId}`]: {
      apiId: damageViewId,
      bbox,
      damageType: damageType,
      isAlgoGenerated: false,
      isDeleted: false,
      isValidated: true,
      partType: partType,
      partViewApiId: partViewId,
    },
  }

  await firestore()
    .collection('turnarounds')
    .doc(turnaroundId)
    .update(update)
    .catch((error) => console.error('Error writing document: ', error))
}

import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'

import { useOnEndReached } from '@/hooks/useOnEndReached'
import useUser from '@/hooks/useUser'

const useGetTurnarounds = () => {
  const user = useUser()
  const [limit, setLimit] = useState<number>(20)
  const [turnaroundsData, setTurnaroundsData] = useState<Array<Turnaround>>([])
  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection('turnarounds')
      .where('userId', '==', user?.id)
      .orderBy('dateOfCreation', 'desc')
      .limit(limit)
  )
  const fetchMore = () => setLimit(limit + 20)
  useOnEndReached({
    disable: false,
    handleOnEndReached: fetchMore,
  })
  const turnarounds = value?.docs.map((doc) => {
    const data = doc.data()
    return {
      ...data,
      dateOfCreation: data?.dateOfCreation?.toDate(),
    } as Turnaround
  })
  useEffect(
    () => {
      const uniqueTurnarounds = _.unionBy(turnaroundsData, turnarounds, 'id')
      setTurnaroundsData(uniqueTurnarounds)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_.size(turnarounds)]
  )

  return {
    fetchMore,
    turnarounds: turnaroundsData,
    loading,
    error,
  }
}

export default useGetTurnarounds

/* eslint-disable prefer-template, react/jsx-no-literals */
// @flow
import React, { memo } from 'react'
import { Route, Switch, withRouter, BrowserRouter } from 'react-router-dom'

import { PAGES } from '@/constants/routes'
import PrivateRoute from '@/connectedComponents/PrivateRoute'
import Home from '@/pages/Home'
import Login from '@/pages/Login'
import Turnaround from '@/pages/Turnaround'

const scrollToTop = () => {
  window.scrollTo(0, 0)
  return null
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Route component={withRouter(scrollToTop)} />{' '}
      {/* we scroll to top each time the route changes */}
      <Switch>
        <PrivateRoute path={PAGES.HOME} exact component={withRouter(Home)} />
        <PrivateRoute
          path={PAGES.TURN_AROUND + '/:turnaroundId'}
          component={withRouter(Turnaround)}
        />
        <Route path={PAGES.LOGIN} exact component={withRouter(Login)} />
      </Switch>
    </BrowserRouter>
  )
}

export default memo<{}>(Routes)

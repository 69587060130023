import * as React from 'react';
import { Image } from "react-konva";

import { useLoadImage } from '../hooks/useLoadImage';

type Props = {
  src: string;
  onMouseDown: any,
  width: number,
  height: number
};

const KonvaImage = ({ src, onMouseDown, width, height }: Props) => {
  const image = useLoadImage(src);
  return (
    <Image
      image={image}
      onMouseDown={onMouseDown}
      width={width}
      height={height}
    />
  );
}

export default KonvaImage;

export const SET_AUTHENTICATION = '@LOGIN_REDUCER/SET_AUTHENTICATION'
export const LOGOUT = '@LOGIN_REDUCER/LOGOUT'

export const setAuthentication = ({ token }: { token: string }) => ({
  token,
  type: SET_AUTHENTICATION,
})

export const logout = () => ({
  token: null,
  type: LOGOUT,
})

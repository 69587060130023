/* eslint-disable sort-keys */
import React from 'react'

import StyledTextField from '@/components/TextField/StyledTextField'
import { FONT_FAMILIES } from '@/constants/design'

const styles: StyleSheets = {
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  errorMessage: {
    color: 'red',
    fontSize: 16,
    position: 'absolute',
    left: 0,
    bottom: -10,
  },
}

type Props = {
  containerStyle?: Object
  dataTestId?: string | null | undefined
  error?: boolean | null | undefined
  errorMessage?: string | null | undefined
  hideOutline?: boolean
  label?: string | null | undefined
  multiline?: boolean
  name?: string
  onBlur?: (arg0: {
    isRequired: boolean
    tag: string | null | undefined
    value: string
  }) => void
  onChange?: (
    tag: string | null | undefined,
    value: string | (number | null | undefined)
  ) => any
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  style?: Object
  tag?: string | null | undefined
  type?: 'text' | 'number' | 'password' | 'email'
  value: (string | null | undefined) | (number | null | undefined)
}

const TextField = (props: Props) => {
  const {
    containerStyle,
    errorMessage,
    hideOutline,
    label,
    multiline,
    name,
    placeholder,
    readOnly,
    required,
    style,
    tag,
    type,
    value,
  } = props
  const onChange = ({ target: { value: newValue } }: any) => {
    if (props.onChange) {
      const numericValue = newValue
        ? parseFloat(newValue.replace(',', '.'))
        : null
      props.onChange(tag ?? null, type === 'number' ? numericValue : newValue)
    }
  }
  const onBlur = (event: any) => {
    if (props.onBlur) {
      props.onBlur({
        isRequired: required ?? false,
        tag: tag ?? null,
        value: event.target.value,
      })
    }
  }

  return (
    <div style={{ ...styles.container, ...containerStyle }}>
      <div style={{ marginBottom: 5, fontFamily: FONT_FAMILIES.DEFAULT }}>
        {label}
      </div>
      <StyledTextField
        text-type={type ?? 'text'}
        disabled={readOnly}
        fullWidth
        multiline={multiline}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        size="small"
        style={style}
        type={type ?? 'text'}
        value={value ?? ''}
        variant={hideOutline ? undefined : 'outlined'}
      />
      {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}

TextField.defaultProps = {
  containerStyle: undefined,
  dataTestId: undefined,
  endAdornment: undefined,
  error: undefined,
  errorMessage: undefined,
  hideOutline: undefined,
  label: undefined,
  multiline: undefined,
  name: undefined,
  onBlur: () => {},
  onChange: () => {},
  placeholder: undefined,
  readOnly: undefined,
  required: undefined,
  style: undefined,
  tag: undefined,
  type: undefined,
}

export default TextField

import React from 'react';
import _ from 'lodash';

import GroupedDamageView from '@/components/GroupedDamageViews/GroupedDamageView';

type Props = {
  damageViews: DamageViewWithIndex[],
  damageViewIndex: number | null,
  setDamageAndImageIndex: (arg: number) => void,
  hasTurnaroundBeenValidated: boolean,
  validateOrDeleteDamageView: Function
};

const GroupedDamageViews = ({
  damageViews, damageViewIndex, hasTurnaroundBeenValidated, setDamageAndImageIndex,
  validateOrDeleteDamageView,
}: Props) => {
  const groupedDamageViews = _.groupBy(damageViews, 'imageIndex');
  const imagesIndex = Object.keys(groupedDamageViews);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      {
        _.map(
          imagesIndex,
          (imageIndex) => (
            <GroupedDamageView
              key={imageIndex}
              // @ts-ignore
              imageIndex={imageIndex}
              damageViews={groupedDamageViews[imageIndex]}
              damageViewIndex={damageViewIndex}
              setDamageAndImageIndex={setDamageAndImageIndex}
              hasTurnaroundBeenValidated={hasTurnaroundBeenValidated}
              validateOrDeleteDamageView={validateOrDeleteDamageView}
            />
          )
        )
      }
    </div>
  );
}

export default GroupedDamageViews;

import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const MonkCard = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: Object
}) => {
  return (
    <Card style={style}>
      <CardContent>{children}</CardContent>
    </Card>
  )
}

export default MonkCard

import _ from 'lodash'
import { useEffect } from 'react'

export const useOnEndReached = ({
  handleOnEndReached,
  disable = false,
}: {
  handleOnEndReached: (...args: any) => any
  disable?: boolean
}) => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeightBody = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight
      )
      const html: any = document.documentElement
      const scrollHeightHtml = Math.max(
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )
      if (
        Math.round(window.innerHeight + window.pageYOffset) + 1 >=
        Math.max(scrollHeightBody, scrollHeightHtml)
      ) {
        if (handleOnEndReached && !disable)
          _.debounce(handleOnEndReached, 300)()
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [disable, handleOnEndReached])
}

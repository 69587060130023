import * as React from 'react'
import _ from 'lodash'
import Check from '@material-ui/icons/Check'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Close from '@material-ui/icons/Close'
import Cancel from '@material-ui/icons/Cancel'
import Accessibility from '@material-ui/icons/Accessibility'

import IconButton from '@/components/Button/IconButton'

import { getMessage } from '@/translations'
import { partTypeToCode, damageTypeToCode } from '@/constants/partAndDamages'
import { LanguageKey } from '@/translations/messages'

const styles: StyleSheets = {
  damageViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 5,
    padding: 5,
    cursor: 'pointer',
  },
  image: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  damages: {
    marginLeft: 20,
  },
}

type GroupedDamageViewProps = {
  imageIndex: number
  damageViews: DamageViewWithIndex[]
  damageViewIndex: number | null
  hasTurnaroundBeenValidated: boolean
  setDamageAndImageIndex: (arg: number) => void
  validateOrDeleteDamageView: Function
}

const GroupedDamageView = ({
  imageIndex,
  damageViews,
  damageViewIndex,
  hasTurnaroundBeenValidated,
  setDamageAndImageIndex,
  validateOrDeleteDamageView,
}: GroupedDamageViewProps) => {
  const activeDamageViewIndexStyle = {
    backgroundColor: '#C9E7F7',
    borderRadius: 5,
  }

  return (
    <div>
      <div style={styles.image}>Image {Number(imageIndex) + 1}</div>
      <div style={styles.damages}>
        {_.map(damageViews, ({ index, ...damage }) => {
          const { isDeleted, isValidated, isAlgoGenerated } = damage
          // @ts-ignore
          const partTypeMessage = getMessage(
            `PART_TYPE.${
              partTypeToCode[
                damage.partType?.toString() as keyof typeof partTypeToCode
              ]
            }` as LanguageKey
          )
          // @ts-ignore
          const damageTypeMessage = getMessage(
            `DAMAGE_TYPE.${
              damageTypeToCode[
                damage.damageType?.toString() as keyof typeof damageTypeToCode
              ]
            }` as LanguageKey
          )
          const isAlgoGenAndValid = isAlgoGenerated && isValidated && !isDeleted
          const isAlgoGenAndNotValid =
            isAlgoGenerated && isValidated && isDeleted
          const isNotAlgoGen = !isAlgoGenerated
          return (
            <div key={index}>
              <div
                style={
                  damageViewIndex === index
                    ? {
                        ...styles.damageViewStyle,
                        ...activeDamageViewIndexStyle,
                      }
                    : styles.damageViewStyle
                }
                key={index}
                onClick={() => {
                  setDamageAndImageIndex(index)
                }}
              >
                {isAlgoGenAndValid && (
                  <Check fontSize="small" htmlColor="green" />
                )}
                {isAlgoGenAndNotValid && (
                  <Close fontSize="small" htmlColor="red" />
                )}
                {isNotAlgoGen && (
                  <Accessibility fontSize="small" htmlColor="blue" />
                )}
                <div style={{ marginLeft: 5 }}>{partTypeMessage}</div>
                <div style={{ flex: 1 }} />
                <div style={{ color: '#A6A8B3' }}>{damageTypeMessage}</div>
              </div>
              {!hasTurnaroundBeenValidated && damageViewIndex === index && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  }}
                >
                  <IconButton
                    color="primary"
                    icon={
                      isValidated && isDeleted ? (
                        <Cancel fontSize="large" htmlColor="red" />
                      ) : (
                        <Close fontSize="large" htmlColor="red" />
                      )
                    }
                    onClick={() => validateOrDeleteDamageView(true)}
                    title="unvalidate"
                  />
                  <IconButton
                    color="primary"
                    icon={
                      isValidated && !isDeleted ? (
                        <CheckCircle fontSize="large" htmlColor="green" />
                      ) : (
                        <Check fontSize="large" htmlColor="green" />
                      )
                    }
                    onClick={() => validateOrDeleteDamageView(false)}
                    title="validate"
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GroupedDamageView

// @flow
import React from 'react'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: { maxWidth: '1000px' },
  // variantError: { backgroundColor: 'rd', opacity: 0.9 },
  // variantInfo: { backgroundColor: COLORS.BLUE, opacity: 0.9 },
  // variantSuccess: { backgroundColor: 'green', opacity: 0.9 },
  // variantWarning: { backgroundColor: COLORS.ORANGE, opacity: 0.9 },
}))

type Props = {
  children: React.ReactNode
}

const ToasterSnackbar = (props: Props) => {
  const { children } = props
  const classes = useStyles()
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={5000}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      classes={{
        root: classes.root,
        // variantError: classes.variantError,
        // variantInfo: classes.variantInfo,
        // variantSuccess: classes.variantSuccess,
        // variantWarning: classes.variantWarning,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default ToasterSnackbar

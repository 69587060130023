import React from 'react'
import _ from 'lodash'
import Accessibility from '@material-ui/icons/Accessibility'
import Android from '@material-ui/icons/Android'

import { getDamageMessage } from '@/services/dataModel/damageView'
import { getPartMessage } from '@/services/dataModel/partType'

const styles: StyleSheets = {
  damage: {
    fontWeight: 'bold',
    marginBottom: 5,
    marginLeft: 5,
  },
  images: {
    cursor: 'pointer',
    marginLeft: 20,
    padding: 5,
    paddingLeft: 10,
  },
}

type Props = {
  damageView: DamageViewWithIndex
  images: any[]
  imageIndex: number
  setImageIndex: (index: number) => void
}

const GroupedImageView = ({
  damageView,
  images,
  imageIndex,
  setImageIndex,
}: Props) => {
  const activeDamageViewIndexStyle = {
    backgroundColor: '#C9E7F7',
    borderRadius: 5,
  }
  const { damageType, partType, isAlgoGenerated } = damageView
  const damageTypeMessage = getDamageMessage(damageType)
  const partTypeMessage = getPartMessage(partType)
  const imageWithIndex = _.map(images, (image, index) => ({ ...image, index }))
  const relevantImages = _.filter(imageWithIndex, ({ damageViews }) =>
    _.find(
      damageViews,
      (damageView: DamageView) =>
        damageView.damageType === damageType &&
        damageView.partType === partType &&
        !damageView.isDeleted
    )
  )
  const icon = isAlgoGenerated ? (
    <Android fontSize="small" htmlColor="green" />
  ) : (
    <Accessibility fontSize="small" htmlColor="blue" />
  )

  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <div>{icon}</div>
        <div style={styles.damage}>
          {damageTypeMessage} - {partTypeMessage}
        </div>
      </div>
      {_.map(relevantImages, ({ index }) => (
        <div
          key={index}
          style={
            index === imageIndex
              ? { ...styles.images, ...activeDamageViewIndexStyle }
              : styles.images
          }
          onClick={() => setImageIndex(index)}
        >
          image - {index + 1}
        </div>
      ))}
    </div>
  )
}

export default GroupedImageView

import React, { useState } from 'react'
import _ from 'lodash'
import { useSnackbar } from 'notistack'

import { updateTurnaround } from '@/services/firestore/turnarounds'
import { cleanlinessOptions } from '@/constants/turnaround'
import Button from '@/components/Button'
import Modal from '@/components/Modals'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'

type Props = {
  turnaround: Turnaround
  isOpen: boolean
  handleClose: any
}

type Maybe<T> = T | null | undefined

const EditTurnaround = ({ isOpen, handleClose, turnaround }: Props) => {
  const [vin, setVin] = useState<Maybe<string>>(turnaround.vin)
  const [registering, setRegistering] = useState<Maybe<string>>(
    turnaround.registering
  )
  const [brand, setBrand] = useState<Maybe<string>>(turnaround.brand)
  const [model, setModel] = useState<Maybe<string>>(turnaround.model)
  const turnaroundCleanliness = _.find(
    cleanlinessOptions,
    (option) => option.code === turnaround.cleanliness
  )
  const [cleanliness, setCleanliness] = useState<
    Maybe<{ code: string; text: string }>
  >(turnaroundCleanliness)
  const { enqueueSnackbar } = useSnackbar()
  const onEditTurnaround = async () => {
    handleClose() // optimistic ui is key
    await updateTurnaround({
      vin,
      brand,
      registering,
      cleanliness: cleanliness?.code,
      model,
      turnaroundId: turnaround.id,
    })
    enqueueSnackbar('The turnaroound has been updated', { variant: 'success' })
  }
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} maxWidth="sm">
      <div>
        <div style={{ textAlign: 'center', fontSize: 25, marginBottom: 40 }}>
          Edit turnaround
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <div style={{ marginTop: 20 }} />
            <TextField
              onChange={(_tag, value: any) => setVin(value)}
              tag="vin"
              value={vin}
              label="Vin"
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              onChange={(_tag, value: any) => setRegistering(value)}
              tag="registering"
              value={registering}
              label="Registering"
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              onChange={(_tag, value: any) => setBrand(value)}
              tag="brand"
              value={brand}
              label="Brand"
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              onChange={(_tag, value: any) => setModel(value)}
              tag="model"
              value={model}
              label="Model"
            />
            <div style={{ marginTop: 20 }} />
            <SelectField
              choices={cleanlinessOptions}
              onChange={(_tag, value) => setCleanliness(value)}
              tag="cleanliness"
              value={cleanliness}
              label="Cleanliness"
            />
            <div style={{ marginTop: 20 }} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 30,
            justifyContent: 'center',
          }}
        >
          <Button
            label="Confirm"
            onClick={onEditTurnaround}
            style={{ width: 200 }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EditTurnaround

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

type Props = {
  color?: string
  size?: number
  style?: React.CSSProperties
}

const ActivityIndicator = ({ color, size, style }: Props) => {
  const Spinner = color
    ? withStyles({ root: { color } })(CircularProgress)
    : CircularProgress
  return <Spinner size={size} style={style} />
}

ActivityIndicator.defaultProps = {
  color: undefined,
  size: undefined,
  style: undefined,
}

export default ActivityIndicator

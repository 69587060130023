import _ from 'lodash'
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'

import ActivityIndicator from '@/components/ActivityIndicator'
import StyledTextField from '@/components/TextField/StyledTextField'

const styles: StyleSheets = {
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}

type Props = {
  choices: any
  error?: boolean
  hideOutline?: boolean
  isQuantityFieldChild?: boolean
  label?: string
  onChange: (tag: string, value: any) => void | Promise<void>
  orderKeys?: Array<string> | null | undefined
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  saveAndSetNewChoiceInConstants?: boolean
  style?: any
  tag: string
  value: any
}

const SelectField = (props: Props) => {
  const {
    choices,
    error,
    label,
    hideOutline,
    placeholder,
    readOnly,
    style,
    tag,
    value,
  } = props
  const numberOfChoices = _.size(choices)
  const Input = StyledTextField
  const onChange = (_event: any, selectedValue: any) => {
    props.onChange(tag, selectedValue)
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <Input
      {...params}
      disabled={readOnly}
      InputProps={{
        ...params.InputProps,
        startAdornment: numberOfChoices === 0 && (
          <InputAdornment position="end">
            <ActivityIndicator color="grey" size={20} />
          </InputAdornment>
        ),
      }}
      error={error}
      fullWidth
      placeholder={placeholder}
      variant={hideOutline ? undefined : 'outlined'}
    />
  )

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={{ marginBottom: 5 }}>{label}</div>
      <Autocomplete
        blurOnSelect
        disabled={readOnly}
        getOptionSelected={(option, value) => value.code === option.code}
        includeInputInList
        openOnFocus
        onChange={onChange}
        options={choices}
        getOptionLabel={(option) => option.text}
        renderInput={renderInput}
        size="small"
        style={{ width: '100%' }}
        value={value}
      />
    </div>
  )
}

SelectField.defaultProps = {
  addNew: undefined,
  addNewCallback: undefined,
  dataTestId: undefined,
  error: undefined,
  hideOutline: undefined,
  isQuantityFieldChild: undefined,
  label: undefined,
  onBlur: undefined,
  orderKeys: undefined,
  readOnly: undefined,
  required: undefined,
  saveAndSetNewChoiceInConstants: undefined,
  style: undefined,
}

export default SelectField

import { useState } from 'react'
import _ from 'lodash';

const useSelectImageAndDamageView = (damageViews: DamageViewWithIndex[]) => {
  const damageViewSize = _.size(damageViews);
  const [imageIndex, setImageIndex] = useState(0)
  const [damageViewIndex, setDamageViewIndex] = useState<number | null>(0)
  const setDamageAndImageIndex = (damageIndex: number) => {
    let newDamageViewIndex = damageIndex;
    if (damageIndex === -1) {
      newDamageViewIndex = damageViewSize - 1;
    } else if (damageIndex === damageViewSize) {
      newDamageViewIndex = 0;
    }
    setDamageViewIndex(newDamageViewIndex);
    setImageIndex(damageViews[newDamageViewIndex]?.imageIndex);
  };
  const setImageIndexAndResetDamageView = (imageIndex: number) => {
    setImageIndex(imageIndex);
    setDamageViewIndex(null);
  };
  return {
    damageViewIndex,
    setDamageAndImageIndex,
    imageIndex,
    setImageIndex: setImageIndexAndResetDamageView,
  }
}

export default useSelectImageAndDamageView
